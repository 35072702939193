.react-datepicker {
  font-family: unset;
  font-size: 0.9rem;
}

.react-datepicker-popper {
  z-index: 20;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__navigation--previous {
  border-right-color: #cbd5e0;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #a0aec0;
}

.react-datepicker__navigation--next {
  border-left-color: #cbd5e0;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #a0aec0;
}

.react-datepicker__header {
  background: #f7fafc;
}

.react-datepicker__header,
.react-datepicker__time-container {
  border-color: #e2e8f0;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: #1e74f6;
}

.react-datepicker__day:hover {
  color: #ffffff !important;
  background: #1e74f6;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: #0747a6;
  font-weight: normal;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: #0747a6;
}

/* .react-datepicker__close-icon::after {
  background-color: unset;
  border-radius: unset;
  font-size: 1.5rem;
  font-weight: bold;
  color: hsl(0, 0%, 80%);
  height: 20px;
  width: 20px;
} */

.react-datepicker__close-icon::after:hover {
  color: hsl(0, 0%, 70%);
}
.react-datepicker__day--today,
.react-datepicker__day--keyboard-selected {
  background-color: white;
  color: black;
}
.react-datepicker__day--today::after {
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  background: orange;
  border-radius: 4px;
  position: absolute;
  margin-left: 12px;
  margin-top: -7px;
}
